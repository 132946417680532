import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    privacyPolicy: false,
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const successMessageRef = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.privacyPolicy) newErrors.privacyPolicy = "You must agree to the privacy policy";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    try {
      // Execute reCAPTCHA and get token
      const token = await executeRecaptcha('contact_form');
      if (!token) {
        setErrors(prevErrors => ({ ...prevErrors, recaptcha: 'Please verify that you are not a robot' }));
        setIsLoading(false);
        return;
      }

      const templateParams = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
        'g-recaptcha-response': token // include the token in your submission
      };

      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      );

      setSuccessMessage('Thank you for your message. I will get back to you shortly.');
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
        privacyPolicy: false,
      });
      setErrors({});
    } catch (err) {
      console.error('Failed to send email:', err);
      setSuccessMessage('Oops! Something went wrong, please try again.');
    } finally {
      setIsLoading(false);
      if (successMessageRef.current) {
        successMessageRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <section className="contact-form-section gridrow">
      <div className='contact-title col-12'>
        <h2 className='col-12'>I'm excited to hear about your project ideas or discuss potential collaborations. Drop me a message below, and I'll get back to you promptly.</h2>
      </div>
      <div className="contact-form-container col-12 col-t-12 col-d-12">
        {successMessage && (
          <p id="success-message" ref={successMessageRef}>{successMessage}</p>
        )}
        <form className='col-12' onSubmit={handleSubmit}>
          <div className='name-container'>
            <label>
              Name*:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <span style={{ color: 'red' }}>{errors.name}</span>}
            </label>
          </div>
          <div className='phone-container'>
            <label>
              Phone:
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className='email-container'>
            <label>
              Email*:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
            </label>
          </div>
          <div className='message-container'>
            <label>
              Message:
              <textarea
                name="message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className='privacy-container'>
            <label>
              <input
                type="checkbox"
                name="privacyPolicy"
                checked={formData.privacyPolicy}
                onChange={handleChange}
              />
              <span>I agree to the <a target='_blank' href='/privacy-policy'>privacy policy*</a></span>
            </label>
            {errors.privacyPolicy && <span style={{ color: 'red' }}>{errors.privacyPolicy}</span>}
          </div>
          <div className='recaptcha-container'>
            {errors.recaptcha && <span style={{ color: 'red' }}>{errors.recaptcha}</span>}
          </div>
          <div className='submit-container'>
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Sending...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;