import Cursor from '../components/cursor/NewCursor';
import Footer from '../components/footer/Footer';
import Menu from '../components/menu/Menu';
import PageTitle from '../components/pageTitle/PageTitle';
import ToTop from '../components/toTop/toTop';
import ContactForm from '../components/contact/ContactForm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import '../style.css';

function Contact() {
  return (
    <div className="App">
      <Cursor />
      <Menu />
      <PageTitle title="Contact me" />
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <ContactForm />
      </GoogleReCaptchaProvider>
      <Footer />
      <ToTop />
    </div>
  );
}

export default Contact;