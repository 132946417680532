import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useNavigate } from "react-router-dom";

// Custom hook to check if the device is mobile
const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

const Portfolio = () => {
    const controls = useAnimation();
    const ref = useRef(null);
    const isMobile = useIsMobile();
    const navigate = useNavigate();

    useEffect(() => {
        if (isMobile) return; // Skip animation setup on mobile

        const element = ref.current;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    controls.start({ opacity: 1, y: 0 });
                } else {
                    controls.start({ opacity: 0, y: 20 });
                }
            },
            { threshold: 0.1 } // Trigger when 10% of the element is visible
        );

        if (element) {
            observer.observe(element);
        }

        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, [controls, isMobile]);

    const handleButtonClick = () => {
        navigate("/projects");
    };

    return (
        <section className="portfolio-section gridrow" ref={ref}>
            {isMobile ? (
                <>
                    <h3>FEATURED WORK</h3>
                    <p>
                        Explore a curated selection of websites developed in close collaboration with our talented design team. These projects showcase the synergy between design and development, resulting in engaging digital experiences that captivate audiences.
                    </p>
                    <button onClick={handleButtonClick}>
                        Discover
                    </button>
                </>
            ) : (
                <>
                    <motion.h3
                        initial={{ opacity: 0, y: 20 }}
                        animate={controls}
                        transition={{ duration: 0.6 }}
                    >
                        FEATURED WORK
                    </motion.h3>
                    <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={controls}
                        transition={{ duration: 0.6, delay: 0.1 }}
                    >
                        Explore a curated selection of websites developed in close collaboration with our talented design team. These projects showcase the synergy between design and development, resulting in engaging digital experiences that captivate audiences.
                    </motion.p>
                    <motion.button
                        initial={{ opacity: 0, y: 20 }}
                        animate={controls}
                        transition={{ duration: 0.6, delay: 0.2 }}
                        onClick={handleButtonClick}
                    >
                        Discover
                    </motion.button>
                </>
            )}
        </section>
    );
}

export default Portfolio;