import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";

// Custom hook to check if the device is mobile
const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

const Work = () => {
    const controls = useAnimation();
    const ref = useRef(null);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (isMobile) return; // Skip animation setup on mobile

        const element = ref.current;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    controls.start({ opacity: 1, y: 0 });
                } else {
                    controls.start({ opacity: 0, y: 20 });
                }
            },
            { threshold: 0.1 } // Trigger when 10% of the element is visible
        );

        if (element) {
            observer.observe(element);
        }

        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, [controls, isMobile]);

    const steps = [
        {
            title: "1. CONNECT & COLLABORATE",
            description: "Let's start by connecting! Reach out to me, and we'll discuss your objectives, design preferences, and desired functionality. I'm here to listen, understand, and collaborate closely with you. This partnership is about bringing your unique vision to life."
        },
        {
            title: "2. CRAFT YOUR VISION",
            description: "With your objectives in mind, I'll dive into crafting a website that represents your brand and resonates with your target audience. We'll work together to create wireframes and mockups, visualizing the end result and ensuring it meets your expectations."
        },
        {
            title: "3. DESIGN & REFINE",
            description: "I'll harness my expertise in design to create a captivating user interface for your website. Whether it's selecting the perfect color palette, choosing fonts that reflect your brand's personality, or creating an intuitive layout, I'll ensure every detail shines."
        },
        {
            title: "4. DEVELOPMENT & IMPLEMENTATION",
            description: "Using the latest web technologies, I'll bring your design to life. From coding and integrating interactive elements to optimizing your website for seamless performance across devices, I'll meticulously build a functional website that engages your visitors."
        },
        {
            title: "5. LAUNCH & SUPPORT",
            description: "The moment has arrived to unveil your website to the world! Before the big launch, we'll conduct thorough testing to ensure everything works seamlessly. Once your website is live, I'll provide you with the necessary training to manage it effectively. And don't worry—I'll always be there to support you with any ongoing maintenance or updates you may need."
        }
    ];

    return (
        <section id="work-id" className="work-section gridrowfull">
            <div className="work-section-container col-12 col-t-12 col-d-6">
                {isMobile ? (
                    <h3>Let's Work</h3> // No animation for mobile
                ) : (
                    <motion.h3
                        ref={ref}
                        initial={{ opacity: 0, y: 20 }}
                        animate={controls}
                        transition={{ duration: 0.6 }}
                    >
                        Let's Work
                    </motion.h3>
                )}
            </div>
            <div className="work-section-container col-12 col-t-12 col-d-6">
                <div className="work-step col-12">
                    {steps.map((step, index) => (
                        isMobile ? (
                            <div key={index} className="work-step-content">
                                <h3>{step.title}</h3>
                                <p>{step.description}</p>
                            </div>
                        ) : (
                            <motion.div
                                key={index}
                                className="work-step-content"
                                initial={{ opacity: 0, y: 20 }}
                                animate={controls}
                                transition={{ duration: 0.3, delay: 0.1 * index }}
                            >
                                <h3>{step.title}</h3>
                                <p>{step.description}</p>
                            </motion.div>
                        )
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Work;