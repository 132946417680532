import Cursor from '../components/cursor/NewCursor';
import '../style.css';
import NotFound from '../components/404/NotFound';
import ParticlesComponent from '../components/particles/Particles';

function NotFound404() {
  return (
    <div className="App">
      <Cursor />
      <NotFound />
      <ParticlesComponent />
    </div>
  );
}

export default NotFound404;