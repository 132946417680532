import React from "react";
import { motion } from "framer-motion";
import { CircleGrid } from 'react-awesome-shapes';
import ArrowIcon from '../../assets/arrow-down.png';

const Info = () => {
    return (
        <section className="info-section gridrowfull">
            <div id="info-section" className="info-section-content">
                <motion.div 
                    className="info-container col-12 col-t-12 col-d-6"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                >
                    <CircleGrid
                        position="relative"
                        color="#ffffff"
                        size="80%"
                        zIndex={2}
                    />
                </motion.div>
                <motion.div 
                    className="info-container col-12 col-t-12 col-d-6"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                >
                    <p>
                        I'm a passionate and dedicated Frontend Developer with a strong background in creating dynamic, user-friendly web applications. Since 2021, I’ve been honing my skills and delivering high-quality projects that exceed client expectations. What I Offer: React & NextJS Development: Crafting modern, responsive, and efficient web applications using the latest technologies. Custom Frontend Solutions: Tailoring designs and functionalities to meet your specific needs and brand identity. PHP & SQL Expertise: Building robust backend functionalities and ensuring seamless data management. WordPress Development: Creating and customizing websites to provide a comprehensive web presence.
                    </p>
                    <span>From Porto, Portugal</span>
                </motion.div>
            </div>
        </section>
    );
}

export default Info;