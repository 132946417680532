import Choose from '../components/choose/Choose';
import ContactSection from '../components/contactSection/ContactSection';
import Cursor from '../components/cursor/NewCursor';
import Footer from '../components/footer/Footer';
import Info from '../components/info/Info';
import Menu from '../components/menu/Menu';
import PageTitle from '../components/pageTitle/PageTitle';
import Stats from '../components/stats/Stats';
import AboutSection from '../components/about/About';
import ToTop from '../components/toTop/toTop';
import '../style.css';

function About() {
  return (
    <div className="App">
      <Cursor />
      <Menu />
      <PageTitle title="About me" />
      <Info />
      <Stats />
      <Choose />
      <AboutSection />
      <ContactSection />
      <Footer />
      <ToTop />
    </div>
  );
}

export default About;