import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Loading from '../src/components/loadingScreen/LoadingScreen';
import './style.css';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NotFound404 from './pages/NotFound';

function App() {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Simulate a data fetch or some initialization task
    const fetchData = async () => {
      for (let i = 0; i <= 100; i++) {
        await new Promise(resolve => setTimeout(resolve, 20)); // Simulate network delay
        setProgress(i);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  // Scroll to top when location changes
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <Router>
      <div className="App">
        {loading ? (
          <Loading progress={progress} />
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/*" element={<NotFound404 />} />
          </Routes>
        )}
        <ScrollToTop />
      </div>
    </Router>
  );
}

export default App;