// src/pages/Home.js
import About from '../components/about/About';
import Cover from '../components/cover/Cover';
import Cursor from '../components/cursor/NewCursor';
import Footer from '../components/footer/Footer';
import Menu from '../components/menu/Menu';
import Portfolio from '../components/portfolio/Portfolio';
import Work from '../components/work/Work';
import ContactSection from '../components/contactSection/ContactSection';
import '../style.css';
import ToTop from '../components/toTop/toTop';
import ServiceHome from '../components/serviceHome/ServiceHome';
import WhatDo from '../components/whatDo/WhatDo';

function Home() {
  return (
    <div className="App">
      <Cursor />
      <Menu />
      <Cover />
      <WhatDo />
      <ServiceHome />
      <Portfolio />
      <Work />
      <ContactSection />
      <Footer />
      <ToTop />
    </div>
  );
}

export default Home;