import ContactSection from '../components/contactSection/ContactSection';
import Cursor from '../components/cursor/NewCursor';
import Footer from '../components/footer/Footer';
import Menu from '../components/menu/Menu';
import PageTitle from '../components/pageTitle/PageTitle';
import ServicesList from '../components/servicesList/ServicesList';
import ToTop from '../components/toTop/toTop';
import '../style.css';

function Services() {
  return (
    <div className="App">
      <Cursor />
      <Menu />
      <PageTitle title="Services" />
      <ServicesList />
      <ContactSection />
      <Footer />
      <ToTop />
    </div>
  );
}

export default Services;