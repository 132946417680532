import React from 'react';

const PrivacyPolicyContent = () => {
  return (
    <section className='privacy-policy-section gridrow'>
      <p>Last updated: August 15, 2024</p>
      <p>
        Welcome to Hugo Quintal's portfolio website, accessible at <a href="https://hugoquintal.pt">hugoquintal.pt</a>.
        This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make contact
        through the website.
      </p>

      <h2>Information Collection and Use</h2>
      <p>
        When you visit the website, certain information is automatically collected about your device, including information
        about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally,
        as you browse the site, information about the individual web pages or products that you view, what websites or search
        terms referred you to the site, and information about how you interact with the site may be collected.
      </p>

      <h2>Cookies</h2>
      <p>
        Cookies are files with a small amount of data that are commonly used as an anonymous unique identifier. These are sent
        to your browser from the website that you visit and are stored on your device's hard drive. The website uses cookies to
        collect information and improve the user experience. You can instruct your browser to refuse all cookies or to indicate
        when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of the
        website.
      </p>

      <h2>Third-Party Services</h2>
      <p>
        This website may employ third-party services that may collect information used to identify you. These third parties
        have their own privacy policies addressing how they use such information. Hugo Quintal is not responsible for the
        privacy practices or the content of these third-party services.
      </p>

      <h2>Security</h2>
      <p>
        The security of your personal information is important, but remember that no method of transmission over the internet,
        or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your
        personal information, we cannot guarantee its absolute security.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        Hugo Quintal reserves the right to update or change this Privacy Policy at any time, and you should check this Privacy
        Policy periodically. Your continued use of the website after any modifications will constitute your acknowledgment of
        the modifications and your consent to abide and be bound by the modified Privacy Policy.
      </p>

      <h2>Contact</h2>
      <p>
        If you have any questions or suggestions about this Privacy Policy, do not hesitate to contact Hugo Quintal at
        <a href="mailto:info@hugoquintal.pt"> info@hugoquintal.pt</a>.
      </p>
    </section>
  );
};

export default PrivacyPolicyContent;