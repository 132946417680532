import React, { useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

const Stats = () => {
    const currentYear = new Date().getFullYear();
    const yearsOfExperience = currentYear - 2021;

    const controls = useAnimation();
    const ref = useRef(null);
    const inView = useInView(ref, { once: true });

    if (inView) {
        controls.start("visible");
    }

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <section className="stats-section gridrowfull">
            <motion.div
                className="stats-container col-12"
                ref={ref}
                variants={containerVariants}
                initial="hidden"
                animate={controls}
            >
                <motion.div className="stats-individual col-6 col-t-4 col-d-4" variants={itemVariants}>
                    <div className="number col-12 col-t-12 col-d-4">
                        <span>0{yearsOfExperience}+</span>
                    </div>
                    <div className="info col-12 col-t-12 col-d-8">
                        <span>Years of Experience</span>
                    </div>
                </motion.div>
                <motion.div className="stats-individual col-6 col-t-4 col-d-4" variants={itemVariants}>
                    <div className="number col-12 col-t-12 col-d-4">
                        <span>30+</span>
                    </div>
                    <div className="info col-12 col-t-12 col-d-8">
                        <span>Client Served</span>
                    </div>
                </motion.div>
                <motion.div className="stats-individual col-6 col-t-4 col-d-4" variants={itemVariants}>
                    <div className="number col-12 col-t-12 col-d-4">
                        <span>50+</span>
                    </div>
                    <div className="info col-12 col-t-12 col-d-8">
                        <span>Integrated Projects</span>
                    </div>
                </motion.div>
            </motion.div>
        </section>
    );
}

export default Stats;