import React, { useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

const ServicesList = () => {
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const SectionWithMotion = ({ children }) => {
    const controls = useAnimation();
    const ref = useRef(null);
    const inView = useInView(ref, { threshold: 0.5 });

    React.useEffect(() => {
      if (inView) {
        controls.start("visible");
      } else {
        controls.start("hidden");
      }
    }, [inView, controls]);

    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={sectionVariants}
        className="services-container col-12 col-t-12 col-d-12"
      >
        {children}
      </motion.div>
    );
  };

  return (
    <section className="servicesList-section gridrowfull">
      <SectionWithMotion>
        <div className="services-container-left col-12 col-t-12 col-d-6">
          <h2>Websites Development</h2>
        </div>
        <div className="services-container-right col-12 col-t-12 col-d-6">
          <p>
            Beautiful, responsive, and user-friendly websites tailored to your
            brand. From personal blogs to corporate sites, I create custom
            solutions that resonate with your audience.
          </p>
          <h4>Key Offerings</h4>
          <ul className="advantages-list">
            <li>
              <span>- Custom Website Design:</span> Tailored designs that reflect your brand identity.
            </li>
            <li>
              <span>- Responsive Development:</span> Ensuring seamless experience across all devices.
            </li>
            <li>
              <span>- SEO-Friendly:</span> Built with best practices to improve search engine ranking.
            </li>
            <li>
              <span>- CMS Integration:</span> Easy-to-manage content with platforms like WordPress.
            </li>
          </ul>
        </div>
      </SectionWithMotion>

      <SectionWithMotion>
        <div className="services-container-left col-12 col-t-12 col-d-6">
          <h2>Web Applications</h2>
        </div>
        <div className="services-container-right col-12 col-t-12 col-d-6">
          <p>
            Powerful, interactive web applications designed to meet your business needs. Whether you're launching a startup or upgrading an existing tool, I build scalable and efficient web apps.
          </p>
          <h4>Key Offerings</h4>
          <ul className="advantages-list">
            <li>
              <span>- Custom Web App Development:</span> Building apps from scratch tailored to your business goals.
            </li>
            <li>
              <span>- Single Page Applications (SPA):</span> Using frameworks like React, Vue.js, or Angular for fast, interactive experiences.
            </li>
            <li>
              <span>- API Integration:</span> Connecting your app with third-party services to extend functionality.
            </li>
            <li>
              <span>- Progressive Web Apps (PWA):</span> Creating apps that work offline and deliver a native app experience.
            </li>
          </ul>
        </div>
      </SectionWithMotion>

      <SectionWithMotion>
        <div className="services-container-left col-12 col-t-12 col-d-6">
          <h2>E-commerce Solutions</h2>
        </div>
        <div className="services-container-right col-12 col-t-12 col-d-6">
          <p>
            Robust e-commerce platforms that drive sales and enhance customer experience. From product management to secure payment integration, I provide end-to-end solutions.
          </p>
          <h4>Key Offerings</h4>
          <ul className="advantages-list">
            <li>
              <span>- Online Store Setup:</span> Creating and customizing e-commerce platforms like Shopify, WooCommerce, or custom-built solutions.
            </li>
            <li>
              <span>- Product Management:</span> Easy-to-use systems for managing inventory, pricing, and categories.
            </li>
            <li>
              <span>- Payment Gateway Integration:</span> Secure and reliable payment processing options.
            </li>
            <li>
              <span>- SEO & Performance Optimization:</span> Ensuring your store is fast, accessible, and optimized for search engines.
            </li>
          </ul>
        </div>
      </SectionWithMotion>

      <SectionWithMotion>
        <div className="services-container-left col-12 col-t-12 col-d-6">
          <h2>Ongoing Maintenance & Support</h2>
        </div>
        <div className="services-container-right col-12 col-t-12 col-d-6">
          <p>
            Keep your digital assets running smoothly with ongoing maintenance and support. From regular updates to troubleshooting, I ensure your website or app is always in top shape.
          </p>
          <h4>Key Offerings</h4>
          <ul className="advantages-list">
            <li>
              <span>- Website & App Maintenance:</span> Regular updates, backups, and security checks.
            </li>
            <li>
              <span>- Bug Fixes & Troubleshooting:</span> Quickly addressing any issues that arise.
            </li>
            <li>
              <span>- Content Updates:</span> Assisting with ongoing content management and updates.
            </li>
            <li>
              <span>- Performance Monitoring:</span> Continually improving speed, security, and user experience.
            </li>
          </ul>
        </div>
      </SectionWithMotion>

      <SectionWithMotion>
        <div className="services-container-left col-12 col-t-12 col-d-6">
          <h2>Consultation & Strategy</h2>
        </div>
        <div className="services-container-right col-12 col-t-12 col-d-6">
          <p>
            Expert advice to help you plan and execute your digital projects. Whether you need guidance on technology choices, project planning, or digital marketing strategies, I’m here to help.
          </p>
          <h4>Key Offerings</h4>
          <ul className="advantages-list">
            <li>
              <span>- Project Consultation:</span> Helping you define project goals, scope, and roadmap.
            </li>
            <li>
              <span>- Technology Strategy:</span> Recommending the best tools and technologies for your needs.
            </li>
            <li>
              <span>- UX/UI Audits:</span> Assessing and improving user experience and interface design.
            </li>
            <li>
              <span>- SEO & Analytics Strategy:</span> Developing strategies to boost traffic and measure success.
            </li>
          </ul>
        </div>
      </SectionWithMotion>
    </section>
  );
};

export default ServicesList;