import React from "react";

const WhatDo = () => {
    return(
        <section id="whatDo" className="what-do-section gridrowfull">
            <div className="what-do-section-title col-12">
                <span>What I Do?</span>
                <h2>Crafting dynamic, intuitive interfaces that bring digital visions to life.</h2>
            </div>
        </section>
    );
}

export default WhatDo;