import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Choose = () => {
    const [selected, setSelected] = useState(null);

    const toggleOpen = (index) => {
        setSelected(selected === index ? null : index);
    };

    const cardsData = [
        {
            title: "Experience & Expertise",
            text: "With several years in the industry, I bring a wealth of knowledge and hands-on experience to your project."
        },
        {
            title: "Client-Centric Approach",
            text: "Your satisfaction is my priority. I ensure clear communication, timely delivery, and top-notch quality."
        },
        {
            title: "Continuous Learning",
            text: "I stay updated with the latest trends and best practices to provide you with cutting-edge solutions."
        }
    ];

    return (
        <section className="choose-section gridrow">
            <div className="choose-container col-12">
                <div className="choose-title col-12">
                    <h3>Why Choose Me?</h3>
                </div>
                <div className="choose-content col-12">
                    {cardsData.map((card, index) => (
                        <ChooseCard 
                            key={index} 
                            card={card} 
                            isOpen={selected === index}
                            onClick={() => toggleOpen(index)}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

const ChooseCard = ({ card, isOpen, onClick }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: true });

    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                transition: { duration: 0.5, ease: "easeOut" }
            });
        }
    }, [controls, inView]);

    return (
        <motion.div 
            className={`choose-card col-12 ${isOpen ? "active" : ""}`} 
            onClick={onClick}
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={controls}
            whileHover={{ scale: 1.05 }} 
            whileTap={{ scale: 0.95 }}
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
        >
            <div className="choose-card-title col-12">
                <h4>{card.title}</h4>
            </div>
            <motion.div
                className="choose-card-text"
                initial={{ height: 0, opacity: 0 }}
                animate={{ 
                    height: isOpen ? "auto" : 0, 
                    opacity: isOpen ? 1 : 0 
                }}
                transition={{ duration: 0.3 }}
                style={{ overflow: "hidden" }}
            >
                <p>{card.text}</p>
            </motion.div>
        </motion.div>
    );
};

export default Choose;