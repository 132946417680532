import React from "react";
import { Link } from "react-router-dom";  // Import Link from react-router-dom

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <section className="footer-section gridrowfull">
            <div className="footer-content col-12">
                <div className="footer-left col-12 col-t-12 col-d-4">
                    <span>© {currentYear} Hugo Quintal. All Rights Reserved.</span>
                </div>
                <div className="footer-center col-6 col-t-12 col-d-4">
                    <ul>
                        <li>
                            <a href="https://www.linkedin.com/in/hugo-quintal" target="_blank" rel="noopener noreferrer">
                                Linkedin
                            </a>
                        </li>
                        <li>
                            <a href="https://github.com/hugoquintal01" target="_blank" rel="noopener noreferrer">
                                Github
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="footer-right col-6 col-t-12 col-d-4">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/projects">Projects</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Footer;