import React from "react";
import { motion } from "framer-motion";

const servicesData = [
  {
    title: "Websites Development",
    description: "Beautiful, responsive, and user-friendly websites tailored to your brand.",
    offerings: [
      { highlight: "- Custom Website Design:", detail: " Tailored designs that reflect your brand identity." },
      { highlight: "- Responsive Development:", detail: " Ensuring seamless experience across all devices." },
      { highlight: "- SEO-Friendly:", detail: " Built with best practices to improve search engine ranking." },
      { highlight: "- CMS Integration:", detail: " Easy-to-manage content with platforms like WordPress." },
    ],
  },
  {
    title: "Web Applications",
    description: "Powerful, interactive web applications designed to meet your business needs.",
    offerings: [
      { highlight: "- Custom Web App Development:", detail: " Building apps from scratch tailored to your business goals." },
      { highlight: "- Single Page Applications (SPA):", detail: " Using frameworks like React, Vue.js, or Angular for fast, interactive experiences." },
      { highlight: "- API Integration:", detail: " Connecting your app with third-party services to extend functionality." },
      { highlight: "- Progressive Web Apps (PWA):", detail: " Creating apps that work offline and deliver a native app experience." },
    ],
  },
  {
    title: "E-commerce Solutions",
    description: "Robust e-commerce platforms that drive sales and enhance customer experience.",
    offerings: [
      { highlight: "- Online Store Setup:", detail: " Creating and customizing e-commerce platforms like Shopify, WooCommerce, or custom-built solutions." },
      { highlight: "- Product Management:", detail: " Easy-to-use systems for managing inventory, pricing, and categories." },
      { highlight: "- Payment Gateway Integration:", detail: " Secure and reliable payment processing options." },
      { highlight: "- SEO & Performance Optimization:", detail: " Ensuring your store is fast, accessible, and optimized for search engines." },
    ],
  },
  {
    title: "Ongoing Maintenance & Support",
    description: "Keep your digital assets running smoothly with ongoing maintenance and support.",
    offerings: [
      { highlight: "- Website & App Maintenance:", detail: " Regular updates, backups, and security checks." },
      { highlight: "- Bug Fixes & Troubleshooting:", detail: " Quickly addressing any issues that arise." },
      { highlight: "- Content Updates:", detail: " Assisting with ongoing content management and updates." },
      { highlight: "- Performance Monitoring:", detail: " Continually improving speed, security, and user experience." },
    ],
  },
  {
    title: "Consultation & Strategy",
    description: "Expert advice to help you plan and execute your digital projects.",
    offerings: [
      { highlight: "- Project Consultation:", detail: " Helping you define project goals, scope, and roadmap." },
      { highlight: "- Technology Strategy:", detail: " Recommending the best tools and technologies for your needs." },
      { highlight: "- UX/UI Audits:", detail: " Assessing and improving user experience and interface design." },
      { highlight: "- SEO & Analytics Strategy:", detail: " Developing strategies to boost traffic and measure success." },
    ],
  },
  {
    title: "Personalized Services",
    description: "Crafted with meticulous attention to detail, tailored specifically to your unique business needs.",
    offerings: [
      { highlight: "- Custom Project Development:", detail: " We collaborate closely with you to create tailored solutions that align with your vision." },
      { highlight: "- Strategic Consulting:", detail: " Personalized advice and guidance to help you navigate complex business challenges." },
      { highlight: "- Innovative Technology Integration:", detail: " Incorporating cutting-edge technologies to give you a competitive edge." },
      { highlight: "- Continuous Improvement:", detail: " Ongoing refinement and enhancement to ensure your solution evolves with your business." },
    ],
  },
];

const ServiceItem = ({ title, description, offerings, delay }) => (
    <div className="service-home-item col-12 col-t-6 col-d-4">
      <motion.div
        className="service-home-item-title col-12"
        initial={{ opacity: 0, x: -50 }}  // Animation start position for title
        whileInView={{ opacity: 1, x: 0 }}  // Animation end position for title
        transition={{ duration: 0.6, delay }}  // Transition settings for title
        viewport={{ once: true }}  // Animation triggers only once
      >
        <h3>{title}</h3>
      </motion.div>
      <motion.div
        className="service-home-item-content col-12"
        initial={{ opacity: 0, x: -50 }}  // Animation start position for content
        whileInView={{ opacity: 1, x: 0 }}  // Animation end position for content
        transition={{ duration: 0.6, delay: delay + 0.2 }}  // Transition settings for content with added delay
        viewport={{ once: true }}  // Animation triggers only once
      >
        <div className="service-home-item-description col-12">
          <span>{description}</span>
        </div>
        <div className="service-home-item-offerings col-12">
          <ul className="advantages-list">
            {offerings.map((offering, index) => (
              <li key={index}>
                <span>{offering.highlight}</span>{offering.detail}
              </li>
            ))}
          </ul>
        </div>
      </motion.div>
    </div>
  );
  
  const ServiceHome = () => {
    return (
      <section className="service-home-section gridrowfull">
        <div className="service-home-container col-12">
          {servicesData.map((service, index) => (
            <ServiceItem
              key={index}
              title={service.title}
              description={service.description}
              offerings={service.offerings}
              delay={index * 0.2}  // Stagger the animation delays
            />
          ))}
        </div>
      </section>
    );
  };
  
  export default ServiceHome;