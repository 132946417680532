import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Menu = () => {
    const [openSubMenu, setOpenSubMenu] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const timeoutRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleSubMenu = (index) => {
        setOpenSubMenu(openSubMenu === index ? null : index);
    };

    const handleMouseEnter = (index) => {
        clearTimeout(timeoutRef.current);
        setOpenSubMenu(index);
    };

    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setOpenSubMenu(null);
        }, 500);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className={`menu ${scrolled ? 'scrolled' : ''}`}>
            <div className="logo">
                <Link to="/">Hugo Quintal</Link>
            </div>
            <div className={`menu-items ${menuOpen ? 'open' : ''}`}>
                <ul>
                    <li
                        onMouseEnter={() => handleMouseEnter(1)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Link to="/about">About</Link>
                    </li>
                    <li
                        onMouseEnter={() => handleMouseEnter(0)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Link to="/services">Services</Link>
                    </li>
                    <li
                        onMouseEnter={() => handleMouseEnter(0)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Link to="/projects">Projects</Link>
                    </li>
                    <li
                        onMouseEnter={() => handleMouseEnter(0)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Link to="/contact">Contact</Link>
                    </li>
                </ul>
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
                <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
                <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
            </div>
        </nav>
    );
};

export default Menu;