import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

const About = () => {
    const controls = useAnimation();

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const sectionTop = document.querySelector("#about").offsetTop;

            // Calculate when to trigger animation based on scroll position
            if (scrollY > sectionTop - window.innerHeight / 2) {
                controls.start({ opacity: 1, y: 0 });
            } else {
                controls.start({ opacity: 0, y: 20 });
            }
        };

        // Subscribe to scroll events
        window.addEventListener("scroll", handleScroll);

        // Clean up on unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [controls]);

    return (
        <motion.section
            id="about"
            className="about-section gridrow"
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
            transition={{ duration: 0.6 }}
        >
            <div className="about-left col-12 col-t-12 col-d-12">
                <span>My Expertise</span>
                <h3>Spearhead the development of Websites and Web Applications</h3>
            </div>
            <div className="about-right col-12 col-t-12 col-d-12">
                <div className="about-list col-12">
                    <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.1 }}
                    >
                        Spearhead the development of websites and web applications from inception to completion, adhering to design mockups provided in <span className="bold">Figma</span> or <span className="bold">Adobe XD</span>
                    </motion.p>
                    <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                    >
                        Proficiently utilized a diverse tech stack encompassing <span className="bold">HTML, JavaScript, React, Next.js, TypeScript, jQuery, PHP, SQL, CSS, SCSS...</span>
                    </motion.p>
                    <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.3 }}
                    >
                        Implemented <span className="bold">API integrations</span> and crafted bespoke <span className="bold">plugins</span> to augment functionality.
                    </motion.p>
                    <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.4 }}
                    >
                        Executed <span className="bold">SEO</span> best practices to ensure <span className="bold">websites rank</span> effectively in search engine results.
                    </motion.p>
                    <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.5 }}
                    >
                        Ensured websites were <span className="bold">responsive</span> across various devices and browsers, <span className="bold">optimizing performance</span> for seamless user experiences.
                    </motion.p>
                    <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.6 }}
                    >
                        Actively engaged in self-directed projects utilizing <span className="bold">React, Next.js</span>, and <span className="bold">TypeScript</span>, demonstrating a commitment to continuous learning and exploring cutting-edge technologies in the frontend development landscape.
                    </motion.p>
                </div>
            </div>
        </motion.section>
    );
}

export default About;