import React from "react";

const PageTitle = ({ title }) => {
    return(
        <section className="pagetitle-section gridrow">
            <h1>{title}</h1>
        </section>
    );
}

export default PageTitle;