import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <section className='not-found-section gridrow'>
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>Oops! The page you're looking for doesn't exist.</p>
      <Link to="/">Go Back to Home</Link>
    </section>
  );
}

export default NotFound;