import React from "react";
import { motion } from "framer-motion";
import ParticlesComponent from "../particles/Particles";
import ArrowIcon from '../../assets/arrow-down.png';

const Cover = () => {
    return (
        <motion.section
            className="cover-section gridrowfull"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.6 }}
        >
            <ParticlesComponent />
            <motion.div
                className="cover-title col-12 col-t-12 col-d-12"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.6, delay: 0.2 }}
            >
                <span>Frontend</span>
                <span>Developer</span>
            </motion.div>
            <a href="#whatDo" className="scroll-down">
                <img src={ArrowIcon} alt="Arrow Icon" />
            </a>  
        </motion.section>
    );
}

export default Cover;