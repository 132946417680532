import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";  // Import Link from react-router-dom

const ContactSection = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: true });

    // Animation for the entire section when it comes into view
    React.useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                transition: { duration: 0.5, ease: "easeOut" }
            });
        }
    }, [controls, inView]);

    return (
        <motion.section 
            className="contact-section gridrowfull"
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={controls}
        >
            <div className="contact-container col-12">
                <div className="contact-left col-12 col-t-12 col-d-8">
                    <motion.h4
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        Got a project in mind?
                    </motion.h4>
                    <motion.h4
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                    >
                        Let's talk
                    </motion.h4>
                </div>
                <div className="contact-right col-12 col-t-12 col-d-4">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.6 }}
                    >
                        <Link to="/contact" className="button">  {/* Use Link instead of a */}
                            Contact Me
                        </Link>
                    </motion.div>
                </div>
            </div>
        </motion.section>
    );
}

export default ContactSection;